@mixin responsive-padding($min, $ideal, $max)
    padding-top: $min

    @media (min-width: 600px)
        padding-top: $ideal

    @media (min-width: 1920px)
        padding-top: $max

.SectionWinAndLevelUp
    padding-top: 1vw
    position: relative
    z-index: 1
    display: flex
    flex-direction: column
    .body
        display: flex
        flex-direction: column
        .subbody
            padding: 0 16px
            @include responsive-padding(7.2vw, 7.2vw, 138px)
        .img-legends
            margin: 0 auto
            width: 80vw
            max-width: 1128px
            width: 100%
            @include responsive-padding(5vw, 5vw, 20px)
    .footer
        @include responsive-padding(1vw, 1vw, 19px)
        display: flex
        justify-content: center
    &-btn
        display: flex
        justify-content: center
        margin-top: 50px

@media (max-width: 768px)
    .SectionWinAndLevelUp
        .body
            .subbody
                .global-title
                    max-width: 230px
                    margin: 0 auto
                .global-subtitle
                    max-width: 300px
                    margin: 0 auto
